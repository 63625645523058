/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Breadcrumb } from "react-bootstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaHome } from "react-icons/fa";
import DailySalesTable from "../../components/salesTable/Sales/salesTable.tsx";
import ProductTable from "../../components/salesTable/productTable.tsx";
import ClosingSalesTable from "../../components/salesTable/closingAccounts/accountTable.tsx";
import InventoryTable from "../../components/inventory/inventoryTable.tsx";
import UpdateHook from "../../../../hooks/updateHooks.tsx";
import SettingService from "../../../../services/settingsService.tsx";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function Settings() {
  const { formData, handleUpdate } = UpdateHook();
  //   const data =  useSelector(state => state)
  const storedData: any = localStorage.getItem("UserData")
  const derivedData = JSON.parse(storedData)
  const ObjectData = derivedData.user_data
  const handleSubmit = async () => {
    const data = {
      user_id: ObjectData.user_id,
      username: ObjectData.username,
      role: ObjectData.role,
      password: formData.password
    }

    try {
      const result: any = await SettingService.UpdatePassword(data)
      console.log("RES", result);
      if (result.data.success === "Successfully Update") {
        toast.success(result.data.success);
        // localStorage.setItem("UserData",  JSON.stringify({ user_data: [data] }));
        window.location.reload()
      } else {
        toast.error(result.data.error);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }
  return (
    <div className="pt-5 container">
      <Breadcrumb>
        <Breadcrumb.Item href="#" className="pt-1 ">
          <div className="col">Settings</div>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="#" className="pt-1 d-flex">
          <div className="pt-1">
            <Link to={"/dashboard"}><FaHome /></Link> 
          </div>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className=" row row-cols-1 card p-5">
        <div className="col p-2 ">
          <div className="card-header"><h5>Change Password</h5></div> 
          <div className="row row-col-3 card-body ">
            <div className="col">
              <label className="form-label fw-bold">
                {" "}
                                username
                <input
                  defaultValue={ObjectData.username}
                  className="form-control input-form"
                  type="text"
                  name="username"
                  onChange={(e) => handleUpdate(e)}
                  readOnly
                />
              </label>
            </div>
            <div className="col">
              <label className="form-label fw-bold">
                {" "}
                                role
                <input
                  defaultValue={ObjectData.role}
                  className="form-control input-form"
                  type="text"
                  name="password"
                  onChange={(e) => handleUpdate(e)}
                  readOnly
                />
              </label>
            </div>
            <div className="col">
              <label className="form-label fw-bold">
                {" "}
                                password
                <input
                  defaultValue={ObjectData.password || ""}
                  className="form-control "
                  type="password"
                  name="password"
                  onChange={(e) => handleUpdate(e)}

                />
              </label>
            </div>
            <div>
              <button className="btn btn-success mt-3" onClick={handleSubmit}>Change Password</button>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}
