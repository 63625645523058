import axios from "axios";
import { Base_URL } from "../constant/BaseUrl.tsx";

axios.defaults.baseURL = Base_URL;
// const cookieHeader = `auth=${cookieValue}; Expires=${expirationDate.toUTCString()}; Path=/; Domain=.yourdomain.com; Secure; SameSite=None`;
axios.interceptors.request.use(
  
  function (config: any) {
    config.headers["Content-Type"] = "application/json";
    // config.header["Cache-Control"] = "max-age=3600"
    // config.headers.common.Accept = "application/json";
    // Ensure headers object is defined
    // config.headers = config.headers || {};
    // config.headers["Cookie"] = 
    // config.headers.post["Access-Control-Allow-Origin"] = "*";
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const SalesService = {
  dailyservice: async (date: any) => {
    // console.log("DETTT", date);
    return await axios.post("/admin/sales/listperday", date );
  },
  createPurchaseService: async (data: any)=> {
    return await axios.post("/sales/purchase", data)
  },
  updatePurchaseService: async (data: any)=> {
    return await axios.put("/sales/updatesales", data)
  },
  deletePurchaseService: async (product_uuid: any) => {
    return await axios.post("/sales/delete", product_uuid)
  },
  closingAccountService: async () => {
    return await axios.get("/list/closebook")
  },
  generateDailySalesService: async () => {
    return await axios.get("/save/closingbook")
  },
  deletedSales: async () => {
    return await axios.get("/sales/getdelete")
  }

};

export default SalesService;
