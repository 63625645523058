import React from "react"
import { NavDropdown, Nav, Navbar } from "react-bootstrap";
import "./navbar.css"
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
function NavbarS() {
  const navigate = useNavigate()
  const handleSelect = (eventKey: any) => {
    if (eventKey === "logout") {
      Cookies.remove("auth")
      localStorage.removeItem("UserData")
      navigate("/")
    } else {
      navigate("/settings")
    }
  };
  return (
    <Navbar fixed="top" expand={"lg"}>
      <Nav variant="pills" activeKey="1" onSelect={handleSelect} className="justify-content-end nav-color pt-3 pb-3" >
        <NavDropdown title="Log Out" id="nav-dropdown" className="pe-4 text-light fw-bold btn-light">
          <NavDropdown.Item eventKey="setting">Settings</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey="logout">Log Out</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
}

export default NavbarS;