/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Breadcrumb, Modal } from "react-bootstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaHome } from "react-icons/fa";
import Pagination from "../../../../../components/paginantion/pagination.tsx";
import _, { upperCase } from "lodash";
import "./sales.css";
import {
  // eslint-disable-next-line import/named
  ColumnFiltersState,
  sortingFns,
  // eslint-disable-next-line import/named
  FilterFn,
  // eslint-disable-next-line import/named
  SortingFn,
  // eslint-disable-next-line import/named
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { fetchData, Sales } from "../../../../../components/createDataTable/makeData.tsx";
import { stringify } from "flatted";
import { DebouncedInput } from "../../../../../helper/tableFilter/debounced.tsx";
import { Filter } from "../../../../../helper/tableFilter/tableFilter.tsx";

import { useLocation } from "react-router";

import handleUpdate from "../../../../../hooks/updateHooks.tsx";
import UpdateHook from "../../../../../hooks/updateHooks.tsx";
import useTableHook from "../../../../../hooks/useReactTableHook.tsx";
import SalesService from "../../../../../services/dailysalesService.tsx";
import { toast } from "react-toastify";
import { resourceLimits } from "worker_threads";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: any;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId].itemRank!,
      rowB.columnFiltersMeta[columnId].itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export default function ClosingSalesTable() {
  // const rerender = React.useReducer(() => ({}), {})[1];
  const { formData, handleUpdate } = UpdateHook();
  console.log(formData);
  const location = useLocation();
  const currentPath = location.pathname;
  const [shoModal, setShowModal] = useState<any>(false);
  const [dates, setDates] = useState({
    date1: "",
    date2: "",
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo<ColumnDef<Sales, any>[]>(
    () => [
      {
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "username",
        header: () => "Username",
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "total_quantity",
        header: () => <span>Total Quantity</span>,
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "total_sales",
        header: () => <span>Total Sales</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "date",
        header: "Time and Date",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "action",
        header: "Action",
        footer: (props) => props.column.id,
      },
    ],

    []
  );
  const [data, setData] = useState<Sales[]>([]);
  const GenerateDailyRecord = async () => {
    try {
      const result: any = await SalesService.generateDailySalesService()
      const dataRes = result.data.results[0]
      if(dataRes.message === "Record Updated successfully"){
        toast.warning("Record Updated successfully")
      }else if (dataRes.message === "Sales Successfully Saved"){
        toast.success("Sales Successfully Saved")
      }else {
        toast.error(dataRes.message)
      }
    } catch (error: any) {
      toast.error(error.message)
    }
  }
  const fetchDataAsync = async () => {
    try {
      const date2 = dates.date2;
      const date1 = dates.date1;
      // console.log("DDD", date2, date1);
      const result = await fetchData(date1, date2, currentPath);
      console.log("DDDDD", result);
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataDebounced = _.debounce(fetchDataAsync, 1000);
  useEffect(() => {
    fetchDataDebounced();
  }, []);
  console.log("DDDD", data)
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Update the state object based on the name of the input field
    setDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };
  const tables = useTableHook(
    fuzzyFilter,
    data,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    columns
  );
  React.useEffect(() => {
    if (tables.getState().columnFilters[0]?.id === "name") {
      if (tables.getState().sorting[0]?.id !== "name") {
        tables.setSorting([{ id: "name", desc: false }]);
      }
    }
  }, [tables.getState().columnFilters[0]?.id]);
  const [tarn, setTarn] = useState();
  const [cellD, setCellD] = useState<any>();
  const handleShow = (e: any, cellId: any) => {
    const name = e.target.name;
    if (name === "update") {
      setShowModal(true);
      setTarn(name);
      setCellD(cellId);
    } else {
      setShowModal(true);
      setTarn(name);
      setCellD(cellId);
    }
  };
  const newQuantity = parseInt(formData?.quantity);
  const newPrice = Math.abs(cellD?.price * newQuantity) || cellD?.totalAmount;
  const handleUpdateSubmit = async () => {
    const data = {
      uuid: cellD?.action,
      product: cellD?.product,
      price: cellD?.price,
      quantity: formData?.quantity,
      totalAmount: newPrice || "",
    };

    try {
      const result: any = await SalesService.updatePurchaseService(data);
      console.log("RES", result.data.message[0]);
      if (result.data.message[0] === "Item Update Successful") {
        toast.success(result.data.message[0]);
        setShowModal(false);
        window.location.reload()
      } else {
        toast.error(result.data.message[0]);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleDelateSubmit = async () => {
    console.log(cellD.action)
    const data = {
      product_uuid: cellD?.action,
    };
    try {
      const results = await SalesService.deletePurchaseService(data);
      console.log(results)
      if (results.data.success === "Item removed") {
        toast.success("Update Successful");
        setShowModal(false);
        window.location.reload()
      } else {
        toast.error(results.data.success);
      }
      console.log(results);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => setShowModal(false);

  return (
    <div className="p-2">
      <div className="row row-cols-2 d-flex justify-content-between">
        <div className="col">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search all columns..."
          />
        </div>
        <div className="row row-cols-3">
          <div className="col">
            <label className="form-label">
              Date From:
              <input
                type="datetime-local"
                className="form-control"
                name="date1"
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="col">
            <label className="form-label">
              Date To:
              <input
                type="datetime-local"
                className="form-control"
                name="date2"
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="col pt-4">
            <button
              className="btn btn-success"
              onClick={() => fetchDataAsync()}
            >
              Fetch
            </button>
          </div>
        </div>
      </div>
      <div className="h-2" />
      <table className="table table-striped table-hover table-bordered overflow-x-scroll">
        <thead>
          {tables.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <th key={index} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} tables={tables} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {tables.getRowModel().rows.map((row, index) => {
            return (
              <tr key={index}>
                {row.getVisibleCells().map((cell) => {
                  // Extract data from the cell or its associated column
                  const cellData: any = flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  );
                  // console.log(cellData);
                  // Check if cellData is a button
                  const cellProps: any = cellData.props;
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "action"
                  ) {
                    const cellId = cellProps.row.original;
                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">
                            <input
                              className="btn bg-success btn-success"
                              type="button"
                              name="view"
                              onClick={(e) => handleShow(e, cellId)}
                              value="View"
                            />
                          
                          </div>
                        </div>
                      </td>
                    );
                  }
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "id"
                  ) {
                    const cellId = cellProps.row.original.id;
                  
                      
                    
                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">{index + 1}</div>
                        </div>
                      </td>
                    )
                  }
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "date"
                  ) {
                    let datecon;

                    if (Array.isArray(cellProps.row.original.date)) {
                      datecon = cellProps.row.original.date.map(
                        (item: Date, index: any) => (
                          <React.Fragment key={index}>
                            {new Date(item).toLocaleDateString()}
                          </React.Fragment>
                        )
                      );
                    } else {
                      datecon = (
                        <React.Fragment key={0}>
                          {new Date(
                            cellProps.row.original.date
                          ).toLocaleString()}
                        </React.Fragment>
                      );
                    }

                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">{datecon}</div>
                        </div>
                      </td>
                    );
                  }
                  return <td key={cell.id}>{cellData}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="d-flex justify-content-center align-items-center gx-2">
        <div className="d-flex">
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(0)}
              disabled={!tables.getCanPreviousPage()}
            >
              {" << "}
            </button>
          </div>
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.previousPage()}
              disabled={!tables.getCanPreviousPage()}
            >
              {" < "}
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.nextPage()}
              disabled={!tables.getCanNextPage()}
            >
              {" > "}
            </button>
          </div>
          <div className="col-6">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(tables.getPageCount() - 1)}
              disabled={!tables.getCanNextPage()}
            >
              {" >> "}
            </button>
          </div>
        </div>
        <div className="col d-flex">
          <div className="col-3">
            <span className="d-flex justify-content-center g-1">
              <div>Page</div>
              <strong>
                {tables.getState().pagination.pageIndex + 1} of{" "}
                {tables.getPageCount()}
              </strong>
            </span>
          </div>

          <div className="col-3">
            <span className="d-flex ">
              <div className="col fs-6"> | Go to page:</div>
              <div className="col">
                <input
                  type="number"
                  defaultValue={tables.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    tables.setPageIndex(page);
                  }}
                  className="border  rounded w-16"
                />
              </div>
            </span>
          </div>
          <div className="col-3">
            <select
              value={tables.getState().pagination.pageSize}
              onChange={(e) => {
                tables.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div>{tables.getPrePaginationRowModel().rows.length} Rows</div>
        </div>
      </div>
      <div className="d-flex mt-2 g-3">
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => fetchDataAsync()}
          >
            Refresh Data
          </button>
        </div>
        <div className="ms-3">
          <button
            className="btn btn-success"
            onClick={() => GenerateDailyRecord()}
          >
            Generate DailySales
          </button>
        </div>
      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
      <Modal
        show={shoModal}
        onHide={handleClose}
        size="lg"
        className="align-items-center d-grid w-100"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textTransform: "uppercase" }}>
            {tarn}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-grid justify-content-center ms-5 ps-5 me-5 pe-5">
          {tarn === "delete" ? (
            <>
              <h3 className="fw-bold">Are you sure ?</h3>
              <div className="row row-col-2 mt-3">
                <div className="col">
                  <button
                    className="btn btn-danger"
                    onClick={handleDelateSubmit}
                  >
                    Delete
                  </button>
                </div>
                <div className="col">
                  <button className="btn btn-dark" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col">
                <label className="form-label fw-bold">
                  {" "}
                  Id:
                  <input
                    defaultValue={cellD?.id}
                    className="form-control input-form"
                    type="text"
                    name="id"
                    onChange={(e) => handleUpdate(e)}
                    readOnly
                  />
                </label>
              </div>
              <div className="row">
                <label className="form-label fw-bold">
                  {" "}
                  Username:
                  <input
                    defaultValue={cellD?.username}
                    className="form-control input-form"
                    type="text"
                    name="product"
                    onChange={(e) => handleUpdate(e)}
                    readOnly
                  />
                </label>
              </div>
              <div className="row">
                <label className="form-label fw-bold">
                  {" "}
                  Total Quantity:
                  <input
                    defaultValue={cellD?.total_quantity}
                    className={
                      tarn === "view"
                        ? "form-control input-form"
                        : "form-control"
                    }
                    type="number"
                    placeholder="Quantity not greater than available"
                    name="quantity"
                    onChange={(e) => handleUpdate(e)}
                    readOnly={tarn === "view" ? true : false}
                  />
                </label>
              </div>
              <div className="col">
                <label className="form-label fw-bold">
                  {" "}
                  Total Amount:
                  <input
                    value={tarn === "view" ? cellD?.total_sales : newPrice}
                    className={
                      tarn === "view"
                        ? "form-control input-form"
                        : "form-control"
                    }
                    type="text"
                    name="totalAmount"
                    onChange={(e) => handleUpdate(e)}
                    readOnly={tarn === "view" ? true : false}
                  />
                </label>
              </div>
              <div className={tarn === "view" ? "col" : "d-none"}>
                <label className="form-label fw-bold">
                  {" "}
                  Time of Purchase:
                  <input
                    defaultValue={new Date(cellD?.date).toLocaleString()}
                    className={
                      tarn === "view"
                        ? "form-control input-form"
                        : "form-control"
                    }
                    type={tarn === "view" ? "text" : "datetime-local"}
                    name="timeday"
                    onChange={(e) => handleUpdate(e)}
                    readOnly={tarn === "view" ? true : false}
                  />
                </label>
              </div>
              <div className="d-flex justify-content-center mt-2 mb-2">
                {tarn === "view" ? (
                  <button
                    className="btn btn-primary text-center fw-bold w-50"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                ) : (
                  <button
                    className="btn btn-warning text-center fw-bold w-50"
                    onClick={handleUpdateSubmit}
                  >
                    Update
                  </button>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
// A debounced input react component
