import { useLocation, useNavigate } from "react-router";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

function ProtectedRoutes({ children }: { children: React.ReactNode }) {
  const [uauth, setUauth] = useState<string | undefined>(undefined);
  const location = useLocation();
  const currentPath = location.pathname;
  const auth: any = Cookies.get("auth");

  useEffect(() => {
    setUauth(auth);
  }, [auth]);

  const userDataString: string | null = localStorage.getItem("UserData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const userD = userData?.user_data;

  const navigate = useNavigate();
  console.log("J",auth)
  if (uauth !== null && uauth !== undefined) {
    if (userD?.role !== "admin" && (currentPath === "/inventorylist" || currentPath === "/usermanager" || currentPath === "/deletedsales")) {
      return <>{navigate("/dashboard", { replace: true })}</>;
    }
    return <>{children}</>;
  } else {
    console.log("Out");
    return <>{navigate("/", { replace: true })}</>;
  }
}

export default ProtectedRoutes;
