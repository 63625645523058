import { createSlice } from "@reduxjs/toolkit";


interface PropsType {
    instances: any
}
const initialState:PropsType = {
  instances: [],
};

const CartSlice = createSlice({
  name: "instances",
  initialState,
  reducers: {
    addInstance: (state, action: {type: any, payload: any}) => {
      state.instances.push(action.payload);
    },
    removeInstance: (state, action) => {
      state.instances = state.instances.filter((instance: { id: any; }) => instance.id !== action.payload);
    },
    updateInstance: (state, action) => {
      const { id, newData } = action.payload;
      const index = state.instances.findIndex((instance: { id: any; }) => instance.id === id);
      if (index !== -1) {
        state.instances[index] = { ...state.instances[index], ...newData };
      }
    },
  },
});

export const { addInstance, removeInstance, updateInstance } = CartSlice.actions;
export default CartSlice.reducer;
