import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route } from "react-router-dom";
import { ClosingAccount, CreateSales, CreditHistory, Inventorylist, Login, PurchaseHistory, Settings, Usermanager } from "./pages/UI/interfaces/index.tsx";
import CustomRoutes from "./components/customRoutes.tsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/store.tsx";
import Dashboard from "./pages/UI/interfaces/dashboard/dashboard.tsx";
import ProtectedRoutes from "./components/protectedRoutes/protectedRoutes.tsx";
import DeletedSales from "./pages/UI/interfaces/deletedsales/deletedsales.tsx";


// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <CustomRoutes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
          <Route path="/createSales" element={<ProtectedRoutes><CreateSales /></ProtectedRoutes>} />
          <Route path="/purchasehistory" element={<ProtectedRoutes><PurchaseHistory /></ProtectedRoutes>} />
          <Route path="/closingaccount" element={<ProtectedRoutes><ClosingAccount /></ProtectedRoutes>} />
          <Route path="/inventorylist" element={<ProtectedRoutes><Inventorylist /></ProtectedRoutes>} />
          <Route path="/usermanager" element={<ProtectedRoutes><Usermanager /></ProtectedRoutes>} />
          <Route path="/settings" element={<ProtectedRoutes><Settings /></ProtectedRoutes>} />
          <Route path="/deletedsales" element={<ProtectedRoutes><DeletedSales /></ProtectedRoutes>} />
          <Route path="/creditlist" element={<ProtectedRoutes><CreditHistory /></ProtectedRoutes>} />
        </CustomRoutes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
