/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Breadcrumb, Modal } from "react-bootstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaHome } from "react-icons/fa";
import Pagination from "../../../../../components/paginantion/pagination.tsx";
import _, { upperCase } from "lodash";
import "./sales.css";
import {
  // eslint-disable-next-line import/named
  ColumnFiltersState,
  sortingFns,
  // eslint-disable-next-line import/named
  FilterFn,
  // eslint-disable-next-line import/named
  SortingFn,
  // eslint-disable-next-line import/named
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { fetchData, Sales } from "../../../../../components/createDataTable/makeData.tsx";
import { stringify } from "flatted";
import { DebouncedInput } from "../../../../../helper/tableFilter/debounced.tsx";
import { Filter } from "../../../../../helper/tableFilter/tableFilter.tsx";

import { useLocation } from "react-router";

import handleUpdate from "../../../../../hooks/updateHooks.tsx";
import UpdateHook from "../../../../../hooks/updateHooks.tsx";
import useTableHook from "../../../../../hooks/useReactTableHook.tsx";
import SalesService from "../../../../../services/dailysalesService.tsx";
import { toast } from "react-toastify";
import { MdDiscount } from "react-icons/md";
import CreditService from "../../../../../services/creditService.tsx";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: any;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId].itemRank!,
      rowB.columnFiltersMeta[columnId].itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export default function GetSalesTable() {
  // const rerender = React.useReducer(() => ({}), {})[1];
  const { formData, handleUpdate } = UpdateHook();
  // console.log(formData);
  const location = useLocation();
  const currentPath = location.pathname;
  const [pItems, setPItems] = useState<any>()
  const [prevPayData, setPrevPayData] = useState<any>()
  const [userRol, setUserRol] = useState<any>()
  const [shoModal, setShowModal] = useState<any>(false);
  const [dates, setDates] = useState({
    date1: "",
    date2: "",
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo<ColumnDef<Sales, any>[]>(
    () => [
      {
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "username",
        header: () => "Username",
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "customer_name",
        header: () => <span>Customer Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "date",
        header: "Date Purchased",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "totalAmount",
        header: "Total Amount",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "action",
        header: "Action",
        footer: (props) => props.column.id,
      },
    ],

    []
  );
  const [data, setData] = useState<Sales[]>([]);
  const fetchDataAsync = async () => {
    try {
      const date2 = dates.date2;
      const date1 = dates.date1;
      // console.log("DDD", date2, date1);
      const result = await fetchData(date1, date2, currentPath);
      // console.log("DDDDD", result);
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataDebounced = _.debounce(fetchDataAsync, 1000);
  useEffect(() => {
    fetchDataDebounced();
  }, []);
  // console.log(data)
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Update the state object based on the name of the input field
    setDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };
  const tables = useTableHook(
    fuzzyFilter,
    data,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    columns
  );
  React.useEffect(() => {
    if (tables.getState().columnFilters[0]?.id === "name") {
      if (tables.getState().sorting[0]?.id !== "name") {
        tables.setSorting([{ id: "name", desc: false }]);
      }
    }
  }, [tables.getState().columnFilters[0]?.id]);
  const [tarn, setTarn] = useState();
  const [cellD, setCellD] = useState<any>();

  const handleShow = async (e: any, cellId: any) => {
    // console.log("D", cellId)
    const name = e.target.name;
    if (name === "update") {
      setShowModal(true);
      setTarn(name);
      setCellD(cellId);
    } else {
      setShowModal(true);
      setTarn(name);
      setCellD(cellId);
    }
    try {
      const data = {
        id: cellId.id
      }
      const lastPaidInfo = await CreditService.getLastPaid(data)
      const creditInfoResponse = await CreditService.getcreditInfo(data)
      if (creditInfoResponse && lastPaidInfo) {
        // console.log("CREDITRES", creditInfoResponse)
        setPItems(lastPaidInfo.data.success[0])
        setPrevPayData(creditInfoResponse.data.success)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const currentPay: number = parseInt(formData?.mPayment);

  const paymentDate = new Date().toLocaleString("en")
  const lastPaidDate = cellD?.lastPayDate !== null ? new Date(cellD?.lastPayDate).toLocaleString("en") : ""
  const purchased = cellD?.purchase !== undefined ? cellD?.purchase : null
  const totalAmountString = cellD?.totalAmount || "0"
  const amountUnP = pItems?.amount_unpaid ? pItems.amount_unpaid : Number(totalAmountString.replace(/,/g, ""))
  const purchasedItem = JSON.parse(purchased)
  // console.log("dixcount", purchasedItem)

  useEffect(() => {
    const usernameString: any = localStorage.getItem("UserData")
    const usernameObj = usernameString !== undefined ? JSON.parse(usernameString) : null
    const userRole = usernameObj?.user_data?.role
    setUserRol(userRole)
  }, [userRol])
  const handleUpdateSubmit = async () => {

    const amountUnpaid = (amountUnP > currentPay) ? Math.abs(amountUnP - currentPay) : toast.error("Amount more than Current Unpaid")

    const data = {
      id: cellD.id,
      last_pay_date: paymentDate,
      last_amount_paid: currentPay,
      amount_unpaid: amountUnpaid,

    };

    try {
      const result: any = await CreditService.updatecredit(data);
      // console.log("RES", result.data.message);
      if (result) {
        toast.success("Credit Updated Successfully");
        setShowModal(false);
        window.location.reload()
      } else {
        toast.error("Issue Updating Credit");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }



  const handleDelateSubmit = async () => {

    const data = {
      id: cellD?.id,
    };
    try {
      const results = await CreditService.deleteCredit(data);
      const info_results = await CreditService.deleteCreditInfo(data);
      // console.log(results)
      if (results.data.success === "OK") {
        toast.success("Deleted Successful");
        setShowModal(false);
        window.location.reload()
      } else if (info_results === undefined) {
        toast.success("Credit Info Not available");
        setShowModal(false);
        window.location.reload()
      } else {
        toast.error(results.data.success);
      }
      // console.log(results);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => setShowModal(false);

  return (
    <div className="p-2">
      <div className="row row-cols-2 d-flex justify-content-between">
        <div className="col">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search all columns..."
          />
        </div>
        <div className="row row-cols-3">
          {/* <div className="col">
            <label className="form-label">
              Date From:
              <input
                type="datetime-local"
                className="form-control"
                name="date1"
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="col">
            <label className="form-label">
              Date To:
              <input
                type="datetime-local"
                className="form-control"
                name="date2"
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="col pt-4">
            <button
              className="btn btn-success"
              onClick={() => fetchDataAsync()}
            >
              Fetch
            </button>
          </div> */}
        </div>
      </div>
      <div className="h-2" />
      <table className="table table-striped table-hover table-bordered overflow-x-scroll">
        <thead>
          {tables.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <th key={index} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} tables={tables} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {tables.getRowModel().rows.map((row, index) => {
            return (
              <tr key={index}>
                {row.getVisibleCells().map((cell) => {
                  // Extract data from the cell or its associated column
                  const cellData: any = flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  );
                  // console.log(cellData);
                  // Check if cellData is a button
                  const cellProps: any = cellData.props;
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "action"
                  ) {
                    const cellId = cellProps.row.original;
                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">
                            <input
                              className="btn bg-success btn-success"
                              type="button"
                              name="view"
                              onClick={(e) => handleShow(e, cellId)}
                              value="View"
                            />
                          </div>

                          <div className={userRol !== "admin" ? "d-none col" : "col"}>
                            <input
                              className="btn bg-danger btn-danger"
                              type="button"
                              name="delete"
                              onClick={(e) => handleShow(e, cellId)}
                              value="Delete"
                              disabled={userRol === "admin" ? false : true}
                            />
                          </div>
                        </div>
                      </td>
                    );
                  }
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "date"
                  ) {
                    let datecon;

                    if (Array.isArray(cellProps.row.original.date)) {
                      datecon = cellProps.row.original.date.map(
                        (item: Date, index: any) => (
                          <React.Fragment key={index}>
                            {new Date(item).toLocaleDateString()}
                          </React.Fragment>
                        )
                      );
                    } else {
                      datecon = (
                        <React.Fragment key={0}>
                          {new Date(
                            cellProps.row.original.date
                          ).toLocaleString()}
                        </React.Fragment>
                      );
                    }

                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">{datecon}</div>
                        </div>
                      </td>
                    );
                  }
                  return <td key={cell.id}>{cellData}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="d-flex justify-content-center align-items-center gx-2">
        <div className="d-flex">
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(0)}
              disabled={!tables.getCanPreviousPage()}
            >
              {" << "}
            </button>
          </div>
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.previousPage()}
              disabled={!tables.getCanPreviousPage()}
            >
              {" < "}
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.nextPage()}
              disabled={!tables.getCanNextPage()}
            >
              {" > "}
            </button>
          </div>
          <div className="col-6">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(tables.getPageCount() - 1)}
              disabled={!tables.getCanNextPage()}
            >
              {" >> "}
            </button>
          </div>
        </div>
        <div className="col d-flex">
          <div className="col-3">
            <span className="d-flex justify-content-center g-1">
              <div>Page</div>
              <strong>
                {tables.getState().pagination.pageIndex + 1} of{" "}
                {tables.getPageCount()}
              </strong>
            </span>
          </div>

          <div className="col-3">
            <span className="d-flex ">
              <div className="col fs-6"> | Go to page:</div>
              <div className="col">
                <input
                  type="number"
                  defaultValue={tables.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    tables.setPageIndex(page);
                  }}
                  className="border  rounded w-16"
                />
              </div>
            </span>
          </div>
          <div className="col-3">
            <select
              value={tables.getState().pagination.pageSize}
              onChange={(e) => {
                tables.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div>{tables.getPrePaginationRowModel().rows.length} Rows</div>
        </div>
      </div>
      <div className="d-flex mt-2 g-3">
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => fetchDataAsync()}
          >
            Refresh Data
          </button>
        </div>
      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
      <Modal
        show={shoModal}
        onHide={handleClose}
        size="lg"
        className="align-items-center d-grid w-100"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textTransform: "uppercase" }}>
            {tarn}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-grid justify-content-center">
          {tarn === "delete" ? (
            <>
              <h3 className="fw-bold">Are you sure ?</h3>
              <div className="row row-col-2 mt-3">
                <div className="col">
                  <button
                    className="btn btn-danger"
                    onClick={handleDelateSubmit}
                  >
                    Delete
                  </button>
                </div>
                <div className="col">
                  <button className="btn btn-dark" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col">
                <div className="p-2">Last Paid Date: {pItems ? new Date(pItems.date_paid).toLocaleString("en") : "-"}</div>
                <div className="p-2">Last Amount Paid: {pItems ? pItems.amount_paid : "-"}</div>
                <div className="p-2">Total Amount UnPaid: <strong>{pItems?.amount_unpaid || cellD?.totalAmount}</strong></div>
                <div className="p-2">
                  <label>
                    <strong>Make payment </strong>
                    <input name="mPayment" type="number" placeholder="input amount paid" className="form-control w-100" onChange={(e) => handleUpdate(e)} />
                  </label>
                </div>
                <hr />
                <div className="mt-3 mb-3">
                  <strong>Previous Payments</strong>
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <th className="p-2">
                        Date Paid
                      </th>
                      <th className="p-2">
                        Amount Paid
                      </th>
                      <th className="p-2">
                        Amount unpaid
                      </th>
                    </thead>
                    <tbody>
                      {prevPayData && prevPayData.map((info: any, index: any) => {
                        return (
                          <tr key={index + 1}>
                            <td>
                              {new Date(info.date_paid).toLocaleString("en")}
                            </td>
                            <td>
                              {info.amount_paid}
                            </td>
                            <td>
                              {info.amount_unpaid}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center mb-3">
                  <button
                    className="btn btn-warning text-center fw-bold w-50"
                    onClick={handleUpdateSubmit}
                  >
                    Upload Payment
                  </button>
                </div>

                <table className="table table-hover table-striped table-bordered">
                  <thead>
                    <th className="p-2">
                      Product
                    </th>
                    <th className="p-2">
                      Variance
                    </th>
                    <th className="p-2">
                      Price
                    </th>
                    <th className="p-2">
                      Quantity
                    </th>
                    <th className="p-2">
                      Discounted Price
                    </th>
                  </thead>
                  <tbody className="text-center">


                    {
                      purchasedItem?.map((items: any, index: number) => {
                        return <tr className="" key={index + 1}>
                          <td>{items.product}</td>
                          <td>{items.variance}</td>
                          <td>{items.price}</td>
                          <td>{items.quantity}</td>
                          <td>{items.discountPrice}</td>
                        </tr>
                      })
                    }

                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center mt-2 mb-2">
                {tarn === "view" ? (
                  <button
                    className="btn btn-primary text-center fw-bold w-50"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
// A debounced input react component
