/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Breadcrumb } from "react-bootstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaHome } from "react-icons/fa";
import DailySalesTable from "../../components/salesTable/Sales/salesTable.tsx";
import ProductTable from "../../components/salesTable/productTable.tsx";
import { Link } from "react-router-dom";

export default function PurchaseHistory() {
  return (
    <div className="pt-5 container">
      <Breadcrumb>
        <Breadcrumb.Item href="#" className="pt-1 ">
          <div className="col">Purchase History</div>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="#" className="pt-1 d-flex">
          <div className="pt-1">
            <Link to={"/dashboard"}><FaHome /></Link> 
          </div>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="pt-5 ">
        <div className="col p-2 ">
          <div className="overflow-x-scroll">
            <DailySalesTable />
          </div>
        </div>

      </div>
    </div>
  );
}
