
import CreditService from "../../services/creditService.tsx";
import SalesService from "../../services/dailysalesService.tsx";
import ProductService from "../../services/productService.tsx";
import UserManagerService from "../../services/usermanager.tsx";



export interface Sales {
  id: number;
  product: string;
  quantity: number;
  product_uuid: string;
  timeday: string;
  totalAmount: number;
  variance: string;
  discount: string
}

export async function fetchData(
  date1: any,
  date2: any,
  currentPath: any
): Promise<Sales[]> {
  console.log("Calling fetchData", currentPath);
  try {
    const date = {
      date1: date1,
      date2: date2,
    };

    if (currentPath === "/createSales") {
      const response = await ProductService.fetchProduct();
      const datar: any = response.data.success;
      console.log("LOOGGING", datar);
      // Map the fetched data to the structure of your Person type
      const formattedData: Sales[] = datar.map((items: any) => ({
        id: items.product_id,
        product: items.product_name,
        price: items.product_price,
        quantity: items.product_quantity,
        remains: items.product_quantity_left,
        variance: items.product_variance,
        discount: items.product_discount
      }));
      return formattedData;
    } else if (currentPath === "/closingaccount") {
      const response: any = await SalesService.closingAccountService();
      const dataRes: any = response.data[0].Success
      const formattedData: Sales[] = dataRes.map((items: any) => ({
        id: items.id,
        username: items.username,
        total_quantity: items.total_quantity,
        total_sales: items.total_sales,
        date: items.date
      }))
      return formattedData
    } else if (currentPath === "/inventorylist"){
      const response: any = await ProductService.fetchProduct()
      const dataRes: any = response.data.success
      console.log("DATT",dataRes)
      const formattedData: Sales[] = dataRes.map((items: any) => ({
        id: items.product_id,
        product: items.product_name,
        price: items.product_price,
        quantity: items.product_quantity,
        remains: items.product_quantity_left,
        variance: items.product_variance,
        discount: items.product_discount
      }))

      return formattedData
      
    }else if(currentPath === "/deletedsales"){
      const response: any = await SalesService.deletedSales()
      console.log(response)
      const dataRes: any = response.data.success
      console.log("DATT",dataRes)
      const formattedData: Sales[] = dataRes.map((items: any) => ({
        id: items.id,
        username: items.username,
        product: items.product,
        quantity: items.quantity,
        price: items.price,
        totalAmount:items.totalAmount,
        date: items.timeday
      }))

      return formattedData
    } else if (currentPath === "/usermanager"){
      const response: any = await UserManagerService.userlist()
      console.log(response)
      const dataRes: any = response.data.success
      console.log("DATT",dataRes)
      const formattedData: Sales[] = dataRes.map((items: any) => ({
        id: items.user_id,
        username: items.username,
        password: items.password,
        role: items.role,
      }))

      return formattedData
      
    } else if (currentPath === "/creditlist"){
      const response: any = await CreditService.getcreditservice()
      console.log(response)
      const dataRes: any = response.data.success
      console.log("DATT",dataRes)
      const formattedData: Sales[] = dataRes.map((items: any) => ({
        id: items.id,
        username: items.username,
        customer_name: items.customer_name,
        totalAmount: items.total_amount,
        date: items.date,
        lastPayDate: items.last_pay_date,
        lastAmountPaid: items.last_amount_paid,
        amountUnpaid: items.amount_unpaid,
        prevPayments: items.prev_pay,
        purchase: items.purchased_items
      }))

      return formattedData
      
    }else {
      const response = await SalesService.dailyservice(date);
      const data: any = response.data.results;
      // console.log("LOOGGING", data);
      // Map the fetched data to the structure of your Person type
      const formattedData: Sales[] = data.map((items: any) => ({
        id: items.id,
        product: items.product,
        quantity: items.quantity,
        variance: items.variance,
        timeday: items.timeday,
        price: items.price,
        discount: items.discount,
        totalAmount: items.totalAmount,
        action: items.product_uuid,
      }));
      return formattedData;
    }


  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
  // useEffect(())
}
