import axios from "axios";
import { Base_URL } from "../constant/BaseUrl.tsx";

axios.defaults.baseURL = Base_URL;
// const cookieHeader = `auth=${cookieValue}; Expires=${expirationDate.toUTCString()}; Path=/; Domain=.yourdomain.com; Secure; SameSite=None`;
axios.interceptors.request.use(

  function (config: any) {
    config.headers["Content-Type"] = "application/json";
    // config.header["Cache-Control"] = "max-age=3600"
    // config.headers.common.Accept = "application/json";
    // Ensure headers object is defined
    // config.headers = config.headers || {};
    // config.headers["Cookie"] = 
    // config.headers.post["Access-Control-Allow-Origin"] = "*";
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const CreditService = {
  newcreditservice: async (data: any, userDetails: any) => {
    // console.log("DETTT", date);
    return await axios.post("/createcredit", { data, userDetails });
  },
  getcreditservice: async () => {
    // console.log("DETTT", date);
    return await axios.get("/creditlist");
  },
  updatecredit: async (data:any) => {
    return await axios.post("/updatecredit", data)
  },
  getcreditInfo: async (id: any) => {
    // console.log("wwall",id)
    return await axios.post("/getcreditInfo", id)
  },
  getLastPaid: async (id: any) => {
    // console.log("wwall",id)
    return await axios.post("/getlastpaid", id)
  },
  deleteCredit: async (data: any) => {
    // console.log("wwall",id)
    return await axios.post("/deletecredit", data)
  },
  deleteCreditInfo: async (data: any) => {
    // console.log("wwall",id)
    return await axios.post("/deletecreditinfo", data)
  }
};

export default CreditService;
