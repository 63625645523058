import { useState } from "react";

const UpdateHook = () => {
  const [formData, setFormData] = useState<any>();
  const handleUpdate = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({ ...prevState, [name]: value }));
  };

  return { formData, handleUpdate };
};

export default UpdateHook;
