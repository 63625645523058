/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Breadcrumb } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaHome } from "react-icons/fa";
import "./dashboard.css"
import SalesService from "../../../../services/dailysalesService.tsx";
import ProductService from "../../../../services/productService.tsx";
import { MdFormatListNumberedRtl } from "react-icons/md";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaSellsy } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router";

export default function Dashboard() {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  const userData: string|any = localStorage.getItem("UserData")
  const rData = JSON.parse(userData)
  const userD = rData?.user_data
  const navigate = useNavigate()
  // console.log(userD)
  const FetchCounter = async () => {

    if (data === null) {
      await ProductService.counterService().then((res: any) => {
        const [response] = res.data.success
        setData(response)
      }).catch(
        (err) => {
          console.log(err)
        }
      )
    }
  }
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      FetchCounter()
    }
  }, []);
  return (
    <div className=" dashboard-bg">
      <div className="pt-5 ms-5 me-5 ">
        <Breadcrumb className="text-white">
          <Breadcrumb.Item href="#" className="pt-1 ">
            <div className="col">Dashboard</div>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" className="pt-1 d-flex">
            <div className="pt-1">
              <FaHome />
            </div>
          </Breadcrumb.Item>

        </Breadcrumb>

        <div className="pt-5 row row-cols-3">
          <div className="col">
            <div className="card nd-bg">
              <div className="card-body p-4">
                <div className="col">
                  <div className="col d-flex justify-content-center fw-bold"><MdFormatListNumberedRtl /> {data?.CounterQ || 0}</div>
                </div>
                <div className="text-center pt-3">Total Quantity Sold</div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card nd-bg">
              <div className="card-body p-4">
                <div className="col">
                  <div className="col d-flex justify-content-center"><FaMoneyBillTrendUp />N{data?.sales || 0}</div>
                </div>
                <div className="text-center pt-3">Total Amount Sold</div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card st-bg">
              <div className="card-body p-4">
                <div className="col">
                  <div className="col d-flex justify-content-center"><FaSellsy />{data?.MaxProduct || "nothing"}</div>
                </div>
                <div className="text-center pt-3">Highest Sold Item</div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 mt-5">
          <div className="text-center pt-5 m-5">
            <h1 className="text-white fs-1 fw-bold">Welcome {userD?.username}</h1>
          </div>
          <div className="m-3 d-flex justify-content-center">
            <button className="btn btn-danger fs-4 fw-bold d-flex " onClick={() => navigate("/createSales")}>Proceed to Sales <IoIosArrowDown className="pt-3" /></button>
          </div>
        </div>
      </div>
    </div>
  );
}
