/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Breadcrumb, Modal } from "react-bootstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaHome } from "react-icons/fa";
import Pagination from "../../../../components/paginantion/pagination.tsx";
import _, { upperCase } from "lodash";
import "./inventory.css";
import {
  // eslint-disable-next-line import/named
  ColumnFiltersState,
  sortingFns,
  // eslint-disable-next-line import/named
  FilterFn,
  // eslint-disable-next-line import/named
  SortingFn,
  // eslint-disable-next-line import/named
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { fetchData, Sales } from "../../../../components/createDataTable/makeData.tsx"
import { stringify } from "flatted";
import { DebouncedInput } from "../../../../helper/tableFilter/debounced.tsx";
import { Filter } from "../../../../helper/tableFilter/tableFilter.tsx";

import { useLocation } from "react-router";

import handleUpdate from "../../../../hooks/updateHooks.tsx";
import UpdateHook from "../../../../hooks/updateHooks.tsx";
import useTableHook from "../../../../hooks/useReactTableHook.tsx";
import SalesService from "../../../../services/dailysalesService.tsx";
import { toast } from "react-toastify";
import { resourceLimits } from "worker_threads";
import ProductService from "../../../../services/productService.tsx";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: any;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId].itemRank!,
      rowB.columnFiltersMeta[columnId].itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export default function InventoryTable() {
  // const rerender = React.useReducer(() => ({}), {})[1];
  const { formData, handleUpdate } = UpdateHook();
  console.log(formData);
  const location = useLocation();
  const currentPath = location.pathname;
  const [shoModal, setShowModal] = useState<any>(false);
  const [dates, setDates] = useState({
    date1: "",
    date2: "",
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo<ColumnDef<Sales, any>[]>(
    () => [
      {
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "product",
        header: () => "Product",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "variance",
        header: () => <span>Variance</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "price",
        header: () => <span>Price</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "discount",
        header: () => <span>Discount</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "quantity",
        header: () => <span>Quantity</span>,
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "remains",
        header: () => <span>Quantity Left</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "action",
        header: "Action",
        footer: (props) => props.column.id,
      },
    ],

    []
  );
  const [data, setData] = useState<Sales[]>([]);
  const GenerateDailyRecord = async () => {
    try {
      const result: any = await SalesService.generateDailySalesService()
      const dataRes = result.data.results[0]
      if (dataRes.message === "Record Updated successfully") {
        toast.warning("Record Updated successfully")
      } else if (dataRes.message === "Sales Successfully Saved") {
        toast.success("Sales Successfully Saved")
      } else {
        toast.error(dataRes.message)
      }
    } catch (error: any) {
      toast.error(error.message)
    }
  }
  const fetchDataAsync = async () => {
    try {
      const date2 = dates.date2;
      const date1 = dates.date1;
      // console.log("DDD", date2, date1);
      const result = await fetchData(date1, date2, currentPath);
      console.log("DDDDD", result);
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataDebounced = _.debounce(fetchDataAsync, 1000);
  useEffect(() => {
    fetchDataDebounced();
  }, []);
  console.log("DDDD", data)
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Update the state object based on the name of the input field
    setDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };
  const tables = useTableHook(
    fuzzyFilter,
    data,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    columns
  );
  React.useEffect(() => {
    if (tables.getState().columnFilters[0]?.id === "name") {
      if (tables.getState().sorting[0]?.id !== "name") {
        tables.setSorting([{ id: "name", desc: false }]);
      }
    }
  }, [tables.getState().columnFilters[0]?.id]);
  const [tarn, setTarn] = useState();
  const [cellD, setCellD] = useState<any>();
  // const [conv, setConv] = useState<any>()
  const handleShow = (e: any, cellId: any) => {
    const name = e.target.name;
    if (name === "update") {
      setShowModal(true);
      setTarn(name);
      setCellD(cellId);
    } else {
      setShowModal(true);
      setTarn(name);
      setCellD(cellId);
    }
  };
  // console.log("DDSA", cellD)
  const handleShowAdd = (e: any) => {
    const name = e.target.name;
    if (name === "addInventory") {
      setShowModal(true);
      setTarn(name);

    } else {
      setShowModal(true);
      setTarn(name);

    }
  };
  const newQuantity = parseInt(formData?.quantity);
  const newPrice = Math.abs(cellD?.price * newQuantity) || cellD?.totalAmount;
  const handleUpdateSubmit = async () => {
    // console.log("HELL", cellD)
    // product_id, product_name, product_price, product_quantity, product_quantity_left
    const product_discount = {
      q2: formData?.q2 || showdisct?.q2,
      q19: formData?.q19 || showdisct?.q19,
      q20: formData?.q20 || showdisct?.q20
    }
    console.log(product_discount)
    const data: any = {
      product_id: cellD?.id,
      product_name: cellD?.product,
      product_price: formData?.price || cellD?.price,
      product_quantity: formData?.quantity || cellD?.quantity,
      product_quantity_left: formData?.totalAmount || cellD?.remains,
      product_discount: JSON.stringify(product_discount),
      product_variance: formData?.variance || cellD?.variance
    };
    console.log("HELL", data)
    try {
      const result: any = await ProductService.updateProduct(data);
      console.log("RES", result);
      if (result.data.success === "Product Update Successful") {
        toast.success(result.data.success);
        setShowModal(false);
        window.location.reload()
      } else {
        toast.error(result.data.success);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  //AddINventory
  const handleAddInventory = async () => {
    console.log("HELL", formData)
    // product_id, product_name, product_price, product_quantity, product_quantity_left
    const product_discount = {
      q2: formData?.q2,
      q19: formData?.q19,
      q20: formData?.q20
    }
    const data = {
      product_name: formData?.product,
      product_price: formData?.price,
      product_quantity: formData?.quantity,
      product_quantity_left: formData?.remains,
      product_discount: JSON.stringify(product_discount),
      product_variance: formData?.variance
    };
    // console.log("HELL", data)
    try {
      const result: any = await ProductService.createProuduct(data);
      console.log("RES", result);
      if (result.data.message === "Product created successfully") {
        toast.success(result.data.message);
        setShowModal(false);
        window.location.reload()
      } else {
        toast.error(result.data.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const handleDelateSubmit = async () => {
    console.log(cellD.action)
    const data = {
      product_id: cellD?.id,
    };
    try {
      const results = await ProductService.deleteProduct(data);
      console.log(results)
      if (results.data.success === "Product deleted successfully") {
        toast.success("Update Successful");
        setShowModal(false);
        window.location.reload()
      } else {
        toast.error(results.data.success);
      }
      console.log(results);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => setShowModal(false);
  const [showdisct, setShowdisct] = useState<any>() 
  const handleConversion = () => {
    let discountQ
    
    try {
      if (cellD !== undefined){
        console.log("FFG", cellD)
        discountQ = JSON.parse(cellD?.discount)
        setShowdisct({...discountQ})
      }
    
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleConversion()
  }, [cellD])
  return (
    <div className="p-1">
      <div className="row row-cols-2 d-flex justify-content-between">
        <div className="col">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search all columns..."
          />
        </div>
        <div className="">


          <div className="col pt-4 pb-3 d-flex justify-content-end">
            <input
              className="btn bg-success btn-success"
              type="button"
              name="addInventory"
              onClick={(e) => handleShowAdd(e)}
              value="Add Inventory"
            />

          </div>
        </div>
      </div>
      <div className="h-2" />
      <table className="table table-striped table-hover table-bordered overflow-x-scroll">
        <thead>
          {tables.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <th key={index} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} tables={tables} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {tables.getRowModel().rows.map((row, index) => {
            return (
              <tr key={index}>
                {row.getVisibleCells().map((cell) => {
                  // Extract data from the cell or its associated column
                  const cellData: any = flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  );
                  // console.log(cellData);
                  // Check if cellData is a button
                  const cellProps: any = cellData.props;
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "action"
                  ) {
                    const cellId = cellProps.row.original;
                    console.log("DDD", cellId)
                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col p-2">
                            <input
                              className="btn bg-success btn-success"
                              type="button"
                              name="view"
                              onClick={(e) => handleShow(e, cellId)}
                              value="View"
                            />

                          </div>
                          <div className="col p-2">
                            <input
                              className="btn bg-warning btn-warning"
                              type="button"
                              name="update"
                              onClick={(e) => handleShow(e, cellId)}
                              value="Update"
                            />

                          </div>
                          <div className="col p-2">
                            <input
                              className="btn bg-danger btn-danger"
                              type="button"
                              name="delete"
                              onClick={(e) => handleShow(e, cellId)}
                              value="Delete"
                            />

                          </div>
                        </div>
                      </td>
                    );
                  }
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "id"
                  ) {
                    const cellId = cellProps.row.original.id;



                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">{index + 1}</div>
                        </div>
                      </td>
                    )
                  }
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "discount"
                  ) {
                    // Assuming cellData contains the data for the current cell
                    const cellData = cellProps.row.original.discount;
                    const conver = JSON.parse(cellData)
                    // setConv(conver)
                    // Check if the cellData is a valid array
                  

                    // If the data is not an array, render the cell as is
                    return <td key={cellProps.column.id} className="row row-cols-1">
                      <div className="col">  {(conver?.q2 !== undefined && conver?.q2 !== null && conver?.q2 !== "") && (<>Q(1-2): <strong>{conver?.q2} </strong></>)}</div> <div className="col">{(conver?.q19 !== undefined  && conver?.q19 !== null && conver?.q19 !== "") && (<>Q(3-19):  <strong> {conver?.q19} </strong></>)}</div><div className="col"> {(conver?.q20 !== undefined  && conver?.q20 !== null && conver?.q20 !== "") && (<>Q(20 or more):  <strong>{conver?.q20}</strong></>)}</div></td>;

                  }
                  if (
                    React.isValidElement(cellData) &&
                    cellProps.column.id === "date"
                  ) {
                    let datecon;

                    if (Array.isArray(cellProps.row.original.date)) {
                      datecon = cellProps.row.original.date.map(
                        (item: Date, index: any) => (
                          <React.Fragment key={index}>
                            {new Date(item).toLocaleDateString()}
                          </React.Fragment>
                        )
                      );
                    } else {
                      datecon = (
                        <React.Fragment key={0}>
                          {new Date(
                            cellProps.row.original.date
                          ).toLocaleString()}
                        </React.Fragment>
                      );
                    }

                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">{datecon}</div>
                        </div>
                      </td>
                    );
                  }
                  return <td key={cell.id}>{cellData}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="d-flex justify-content-center align-items-center gx-2">
        <div className="d-flex">
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(0)}
              disabled={!tables.getCanPreviousPage()}
            >
              {" << "}
            </button>
          </div>
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.previousPage()}
              disabled={!tables.getCanPreviousPage()}
            >
              {" < "}
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.nextPage()}
              disabled={!tables.getCanNextPage()}
            >
              {" > "}
            </button>
          </div>
          <div className="col-6">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(tables.getPageCount() - 1)}
              disabled={!tables.getCanNextPage()}
            >
              {" >> "}
            </button>
          </div>
        </div>
        <div className="col d-flex">
          <div className="col-3">
            <span className="d-flex justify-content-center g-1">
              <div>Page</div>
              <strong>
                {tables.getState().pagination.pageIndex + 1} of{" "}
                {tables.getPageCount()}
              </strong>
            </span>
          </div>

          <div className="col-3">
            <span className="d-flex ">
              <div className="col fs-6"> | Go to page:</div>
              <div className="col">
                <input
                  type="number"
                  defaultValue={tables.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    tables.setPageIndex(page);
                  }}
                  className="border  rounded w-16"
                />
              </div>
            </span>
          </div>
          <div className="col-3">
            <select
              value={tables.getState().pagination.pageSize}
              onChange={(e) => {
                tables.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div>{tables.getPrePaginationRowModel().rows.length} Rows</div>
        </div>
      </div>
      <div className="d-flex mt-2 g-3">
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => fetchDataAsync()}
          >
            Refresh Data
          </button>
        </div>

      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
      <Modal
        show={shoModal}
        onHide={handleClose}
        size="lg"
        className="align-items-center d-grid w-100"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textTransform: "uppercase" }}>
            {tarn === "addInventory" ? "Add Inventory" : tarn}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-grid justify-content-center ms-5 ps-5 me-5 pe-5">
          {tarn === "delete" ? (
            <>
              <h3 className="fw-bold">Are you sure ?</h3>
              <div className="row row-col-2 mt-3">
                <div className="col">
                  <button
                    className="btn btn-danger"
                    onClick={handleDelateSubmit}
                  >
                    Delete
                  </button>
                </div>
                <div className="col">
                  <button className="btn btn-dark" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : (
            tarn === "addInventory" ? (

              <>

                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Product Name:
                    <input

                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="text"
                      name="product"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Product Price:
                    <input

                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="text"
                      name="price"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Variance:
                    <input

                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="text"
                      name="variance"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Quantity:
                    <input

                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="number"
                      placeholder=""
                      name="quantity"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Quantity Left:
                    <input

                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="text"
                      name="remains"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>

                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Discount (Quantity: Q):
                    <div className="d-flex">
                      <div className="col m-2">
                        <i> Q: 1-2</i>
                        <select className="form-control" name="q2" onChange={(e) => handleUpdate(e)}>

                          <option value="">0</option>
                          {Array.from({ length: 100 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>{index + 1} %</option>
                          ))
                          }
                        </select>
                      </div>
                      <div className="col m-2">
                        <i> Q: 3-19</i>
                        <select className="form-control" name="q19" onChange={(e) => handleUpdate(e)}>

                          <option value="">0</option>
                          {Array.from({ length: 100 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>{index + 1} %</option>
                          ))
                          }
                        </select>
                      </div>
                      <div className="col m-2">
                        <i> Q: &gt; 19 </i>
                        <select className="form-control" name="q20" onChange={(e) => handleUpdate(e)}>
                          <option value="">0</option>
                          {Array.from({ length: 100 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>{index + 1} %</option>
                          ))
                          }
                        </select>
                      </div>
                    </div>


                  </label>
                </div>
                <button
                  className="btn btn-success text-center fw-bold w-100"
                  onClick={handleAddInventory}
                >
                  Add Inventory
                </button>
              </>
            ) : (
              <>

                <div className="col">
                  <label className="form-label fw-bold">
                    {" "}
                    Id:
                    <input
                      defaultValue={cellD?.id}
                      className="form-control input-form"
                      type="text"
                      name="id"
                      onChange={(e) => handleUpdate(e)}
                      readOnly
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Product Name:
                    <input
                      defaultValue={cellD?.product}
                      className="form-control input-form"
                      type="text"
                      name="product"
                      onChange={(e) => handleUpdate(e)}
                      readOnly
                    />
                  </label>
                </div> 
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Product Variant:
                    <input
                      defaultValue={cellD?.variance}
                      className="form-control input-form"
                      type="text"
                      name="product"
                      onChange={(e) => handleUpdate(e)}
                      readOnly
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Product Price:
                    <input
                      defaultValue={cellD?.price}
                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="text"
                      name="price"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>

                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Quantity:
                    <input
                      defaultValue={cellD?.quantity}
                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="number"
                      placeholder="Quantity not greater than available"
                      name="quantity"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>
                <div className="col">
                  <label className="form-label fw-bold">
                    {" "}
                    Quantity Left:
                    <input
                      defaultValue={cellD?.remains}
                      className={
                        tarn === "view"
                          ? "form-control input-form"
                          : "form-control"
                      }
                      type="text"
                      name="totalAmount"
                      onChange={(e) => handleUpdate(e)}
                      readOnly={tarn === "view" ? true : false}
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="form-label fw-bold">
                    {" "}
                    Product Discount (Quantity: Q):
                    <div className="d-flex">
                      <div className="col m-2">
                        <i> Q: 1-2</i>
                        <select className="form-control" name="q2" defaultValue={showdisct?.q2 || 0} disabled={tarn === "view" ? true : false} onChange={(e) => handleUpdate(e)}>

                          <option value="">0</option>
                          {Array.from({ length: 100 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>{index + 1} %</option>
                          ))
                          }
                        </select>
                      </div>
                      <div className="col m-2">
                        <i> Q: 3-19</i>
                        <select className="form-control" name="q19" defaultValue={showdisct?.q19 || 0} disabled={tarn === "view" ? true : false} onChange={(e) => handleUpdate(e)}>

                          <option value="">0</option>
                          {Array.from({ length: 100 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>{index + 1} %</option>
                          ))
                          }
                        </select>
                      </div>
                      <div className="col m-2">
                        <i> Q: &gt; 19 </i>
                        <select className="form-control" name="q20" defaultValue={showdisct?.q20 || 0} disabled={tarn === "view" ? true : false} onChange={(e) => handleUpdate(e)}>
                          <option value="">0</option>
                          {Array.from({ length: 100 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>{index + 1} %</option>
                          ))
                          }
                        </select>
                      </div>
                    </div>

                        
                  </label>
                </div>
                <div className="d-flex justify-content-center mt-2 mb-2">
                  {tarn === "view" ? (
                    <button
                      className="btn btn-primary text-center fw-bold w-50"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  ) : (
                    (
                      <button
                        className="btn btn-warning text-center fw-bold w-50"
                        onClick={handleUpdateSubmit}
                      >
                        Update
                      </button>

                    ))}
                </div>
              </>)
          )
          }
        </Modal.Body >
      </Modal >
    </div >
  );
}
// A debounced input react component
