/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  // eslint-disable-next-line import/named

  useReactTable,
  // eslint-disable-next-line import/named
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  // eslint-disable-next-line import/named
  FilterFn,
  // eslint-disable-next-line import/named
  SortingFn,
  // eslint-disable-next-line import/named
  ColumnDef,
  flexRender,
  // eslint-disable-next-line import/named
  FilterFns,
} from "@tanstack/react-table";

import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import React, { ReactNode, useEffect, useState } from "react";
import { stringify } from "flatted";
// import { fetchData, Sales } from "./makeData.tsx";

import _ from "lodash";
import { DebouncedInput } from "../../../../helper/tableFilter/debounced.tsx";
import { Filter } from "../../../../helper/tableFilter/tableFilter.tsx";
import { Sales } from "../../../../components/createDataTable/makeData.tsx";

import { fetchData } from "../../../../components/createDataTable/makeData.tsx";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addInstance,
  removeInstance,
  updateInstance,
} from "../../../../slice/cartSlice/cartSlice.tsx";
import { toast } from "react-toastify";
import SalesService from "../../../../services/dailysalesService.tsx";
import { Breadcrumb, Modal } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import CreditService from "../../../../services/creditService.tsx";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: any;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId].itemRank!,
      rowB.columnFiltersMeta[columnId].itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export default function CreateSales() {
  // const rerender = React.useReducer(() => ({}), {})[1];

  const location = useLocation();
  const [cartItem, setCartItem] = useState<any>({});
  const [show, setShow] = useState(false)
  const currentPath = location.pathname;
  const [totalPrice, setTotalPrice] = useState<number>();
  const [dataRR, setDataRR] = useState<any>()
  const [itemId, setItemId] = useState<any>();
  const [creditValue, setCreditValue] = useState("")
  // console.log("CURRENTPATH:", currentPath);
  const [dates, setDates] = useState({
    date1: "",
    date2: "",
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo<ColumnDef<Sales, any>[]>(
    () => [
      {
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "product",
        header: () => "Product",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "variance",
        header: () => <span>Variance</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "price",
        header: () => <span>Price</span>,
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "remains",
        header: "Quantity Left",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "product_quantity",
        header: () => <span>Quantity</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "action",
        header: "Action",
        footer: (props) => props.column.id,
      },
    ],

    []
  );
  const [data, setData] = useState<Sales[]>([]);
  const fetchDataAsync = async () => {
    try {
      const date2 = dates.date2;
      const date1 = dates.date1;
      // console.log("DDD", date2, date1);
      const result = await fetchData(date1, date2, currentPath);
      console.log("DDDDD", result);
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataDebounced = _.debounce(fetchDataAsync, 1000);
  useEffect(() => {
    fetchDataDebounced();
  }, []);
  console.log("DATA", data)
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Update the state object based on the name of the input field
    setDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  const tables = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });
  React.useEffect(() => {
    if (tables.getState().columnFilters[0]?.id === "name") {
      if (tables.getState().sorting[0]?.id !== "name") {
        tables.setSorting([{ id: "name", desc: false }]);
      }
    }
  }, [tables.getState().columnFilters[0]?.id]);
  const dispatch = useDispatch();

  const handleProductChange = (cellId: any, e: any) => {
    const QuantityValue = e.target.value;
    const { name } = e.target
    console.log("FFF", name)
    if (cellId.products === name) {
      setItemId(QuantityValue)
    }
    if (cellId.remains < QuantityValue) {
      toast.error("Value more than available");
    }
    console.log("ssss", cellId)
    console.log("WE", data)
    const updatedData = data.map((item: any) => {

      if (item.id === cellId.id) {
        const discount = (item.discount !== null && item.discount !== undefined) ? JSON.parse(item.discount) : 0;
        console.log("DIS", discount)
        setDataRR(discount)
        if (QuantityValue < 3) {
          const discountValue = (discount.q2 !== null && discount.q2 !== undefined) ? discount.q2 : 0
          const initalTotalAmount = Math.abs(item.price * QuantityValue);
          const discountTotalAmount = initalTotalAmount - ((initalTotalAmount / 100) * (discountValue))
          console.log("dhdhf", discountTotalAmount)
          const itemId = item.id;
          const itemPrice = item.price;
          const itemProduct = item.product;
          const itemVariance = item.variance;
          const itemDiscount = discountValue;
          const items = { itemId, itemPrice, itemProduct, itemDiscount, itemVariance };
          const payload = {
            ...items,
            quantityAmount: QuantityValue,
            discountTotalAmount,
          };
          setCartItem(payload);
          return { ...item, quantity: QuantityValue, discountTotalAmount };
        }
        else if (QuantityValue < 20) {
          const discountValue = (discount.q19 !== null && discount.q19 !== undefined) ? discount.q19 : 0
          const initalTotalAmount = Math.abs(item.price * QuantityValue);
          const discountTotalAmount = initalTotalAmount - ((initalTotalAmount / 100) * (discountValue))
          console.log("dhdhf", discountTotalAmount)
          const itemId = item.id;
          const itemPrice = item.price;
          const itemProduct = item.product;
          const itemVariance = item.variance;
          const itemDiscount = discountValue;
          const items = { itemId, itemPrice, itemProduct, itemDiscount, itemVariance };
          const payload = {
            ...items,
            quantityAmount: QuantityValue,
            discountTotalAmount,
          };
          setCartItem(payload);
          return { ...item, quantity: QuantityValue, discountTotalAmount };
        } else {
          const discountValue = (discount.q20 !== null && discount.q20 !== undefined) ? discount.q20 : 0
          const initalTotalAmount = Math.abs(item.price * QuantityValue);
          const discountTotalAmount = initalTotalAmount - ((initalTotalAmount / 100) * (discountValue))
          console.log("dhdhf", discountTotalAmount)
          const itemId = item.id;
          const itemPrice = item.price;
          const itemProduct = item.product;
          const itemVariance = item.variance;
          const itemDiscount = discountValue;
          const items = { itemId, itemPrice, itemProduct, itemDiscount, itemVariance };
          const payload = {
            ...items,
            quantityAmount: QuantityValue,
            discountTotalAmount,
          };
          setCartItem(payload);
          return { ...item, quantity: QuantityValue, discountTotalAmount };
        }
      }

      // console.log("ITEMM", {item, cellId})
      // return {item};
    });
    console.log("GGG", updatedData);
  };
  // dispatch(addInstance({ id: itemId, payload }));
  console.log("SETUP", dataRR)
  const handleCart = (cellId: any) => {
    console.log("BYDEFAULT", cellId);

    if (cartItem !== undefined && cellId.remains >= cartItem.quantityAmount) {
      console.log("SSS", cartItem);
      if (instance.instances.length > 0) {

        // If there are instances in the store
        const [firstItem] = instance.instances;
        const dItem = instance.instances;
        // eslint-disable-next-line prefer-const
        let itemss = [] as any;
        dItem.map((item: any) => {
          if (item.id === cellId.id) {
            itemss.push(item.id);
          }
        });
        console.log(itemss);
        // If an instance with the same id is found, update it
        if (cellId.id === cartItem.itemId && itemss[0] === cellId.id) {
          // console.log("EQUAL", itemss);
          if (cartItem !== undefined) {
            const dixcount = JSON.stringify(dataRR)
            // console.log("CARTIT", firstItem.id);
            dispatch(
              updateInstance({
                id: cartItem.itemId,
                newData: { ...cartItem, dixcount },
              })
            );
          }
        } else {
          // If no match is found, add a new instance
          if (
            cellId.id === cartItem.itemId &&
            !instance.instances.some((item: any) => item.id === cellId.id)
          ) {
            console.log("GETTI", { cellId, dataRR });
            // const discount = cellId.discount
            const cartitem = cellId.id !== firstItem.id ? cartItem : null;
            const dixcount = JSON.stringify(dataRR)
            dispatch(addInstance({ id: cartItem.itemId, ...cartitem, dixcount }));
          }
        }
      } else {
        if (cellId.remains >= cartItem.quantityAmount) {
          const dixcount = JSON.stringify(dataRR)
          dispatch(addInstance({ id: cartItem.itemId, ...cartItem, dixcount }));
        }

      }
    } else {
      console.log("DSS", cartItem);
      // If there are no instances in the store, add a new instance
      if (cellId.remains >= cartItem.quantityAmount) {
        const dixcount = JSON.stringify(dataRR)
        dispatch(addInstance({ id: cartItem.itemId, ...cartItem, dixcount }));
      }
    }
  };

  useEffect(() => {
    handleCart;
  }, []);

  const instance = useSelector((state: any) => state.cartSlice);
  console.log("REDUX", instance);
  const totalAmountSum = instance.instances.reduce(
    (sum: any, item: any) => sum + item.discountTotalAmount,
    0
  );
  useEffect(() => {
    const formattedPrice = totalAmountSum.toLocaleString("en")
    setTotalPrice(formattedPrice);
    console.log(totalPrice);
  }, [totalAmountSum]);

  const handleCheckout = async () => {
    if (instance.instances !== undefined && instance.instances.length > 0) {

      const data = instance.instances.map((item: any) => ({
        product: item.itemProduct,
        quantity: item.quantityAmount,
        price: item.itemPrice,
        totalPrice: item.discountTotalAmount,
        discount: item.itemDiscount,
        variance: item.itemVariance,
        credit: false,
      }));

      await SalesService.createPurchaseService(data)
        .then((result: any) => {
          if (result !== undefined) {
            toast.success("Item Purchase Successful");
            window.location.reload();
            setTotalPrice(0);
            instance.instances.map((item: any) =>
              dispatch(removeInstance(item.id))
            );
            // dispatch(removeInstance());
          }
        })
        .catch((error: any) => {
          toast.error("Error Purchasing Item:", error.message);
        });
    }
  };
  const handleCreditChange = (e: any) => {
    const { name, value } = e.target
    if (name === "customer") {
      setCreditValue(value)
    }
  }
  const handleCheckoutCredit = async () => {

    if (creditValue) {
      if (instance.instances !== undefined && instance.instances.length > 0) {
        const data = instance.instances.map((item: any) => ({
          product: item.itemProduct,
          quantity: item.quantityAmount,
          price: item.itemPrice,
          discountPrice: item.discountTotalAmount,
          discount: item.itemDiscount,
          variance: item.itemVariance,
          customer_name: creditValue
        }));
        const userDetails = {
          customer_name: creditValue,
          totalAmount: totalPrice as number
        }
        await CreditService.newcreditservice(data, userDetails)
          .then((result: any) => {
            if (result !== undefined) {
              toast.success("Item Purchased on Credit ");
              window.location.reload();
              setTotalPrice(0);
              instance.instances.map((item: any) =>
                dispatch(removeInstance(item.id))
              );
              // dispatch(removeInstance());
            }
          })
          .catch((error: any) => {
            toast.error("Error Purchasing Item:", error.message);
          });
      }
    } else {
      toast.error("customer name required")
    }
  };
  const handleShow = () => { setShow(true) }
  const handleHide = () => { setShow(false) }
  const removeItem = (item: any) => {
    dispatch(removeInstance(item));
  };

  return (

    <div className="pt-5 container overflow-x-scroll">
      <Breadcrumb>
        <Breadcrumb.Item href="#" className="pt-1 ">
          <div className="col">Create Sales</div>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="#" className="pt-1 d-flex">
          <div className="pt-1">
            <FaHome />
          </div>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row row-cols-2 ">
        <div className="col">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search all columns..."
          />
        </div>
        <div className="col ">
          <div className="row">
            <div className="col">
              <label className="form-label">
                Date From:
                <input
                  type="datetime-local"
                  className="form-control"
                  name="date1"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="col">
              <label className="form-label">
                Date To:
                <input
                  type="datetime-local"
                  className="form-control"
                  name="date2"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="col pt-4">
              <button
                className="btn btn-success"
                onClick={() => fetchDataAsync()}
              >
                Fetch
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-2 overflow-x-scroll" />
      <table className="table table-striped table-hover table-bordered overflow-x-scroll">
        <thead>
          {tables.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <th key={index} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} tables={tables} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {tables.getRowModel().rows.map((row, index) => {
            return (
              <tr key={index}>
                {row.getVisibleCells().map((cell) => {
                  // Extract data from the cell or its associated column

                  const cellData: any = flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  );
                  // console.log(cellData);
                  // Check if cellData is a button
                  const cellD: any = cellData.props
                  if (
                    React.isValidElement(cellData) &&
                    cellD.column.id === "product_quantity"
                  ) {
                    const cellId = cellD.row.original;

                    return (
                      <td key={cell.id}>
                        <input
                          type="number"
                          maxLength={10}
                          max={cellId.remains}
                          name={cellId.product}
                          className="form-control"
                          placeholder={`Not more than ${cellId.remains} left`}
                          onChange={(e) => {
                            handleProductChange(cellId, e);
                          }}
                        />
                      </td>
                    );
                  }
                  if (
                    React.isValidElement(cellData) &&
                    (cellD.column.id as any) === "action"
                  ) {
                    const cellId = cellD.row.original;
                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}

                        <div className="col">
                          <button
                            className="btn btn-success"
                            onClick={() => handleCart(cellId)}
                          >
                            Add to cart
                          </button>
                        </div>
                      </td>
                    );
                  }
                  if (
                    React.isValidElement(cellData) &&
                    cellD.column.id === "timeday"
                  ) {
                    console.log(
                      "CELL",
                      new Date(cellD.row.original.timeday)
                    );
                    let datecon;

                    if (Array.isArray(cellD.row.original.timeday)) {
                      datecon = cellD.row.original.timeday.map(
                        (item: Date, index: any) => (
                          <React.Fragment key={index}>
                            {new Date(item).toLocaleDateString()}
                          </React.Fragment>
                        )
                      );
                    } else {
                      datecon = (
                        <React.Fragment key={0}>
                          {new Date(
                            cellD.row.original.timeday
                          ).toLocaleString()}
                        </React.Fragment>
                      );
                    }

                    return (
                      <td key={cell.id}>
                        {/* Render the action button */}
                        <div className="row row-col-3">
                          <div className="col">{datecon}</div>
                        </div>
                      </td>
                    );
                  }

                  return <td key={cell.id}>{cellData}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="d-flex justify-content-center align-items-center gx-2">
        <div className="d-flex">
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(0)}
              disabled={!tables.getCanPreviousPage()}
            >
              {" << "}
            </button>
          </div>
          <div className="col">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.previousPage()}
              disabled={!tables.getCanPreviousPage()}
            >
              {" < "}
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.nextPage()}
              disabled={!tables.getCanNextPage()}
            >
              {" > "}
            </button>
          </div>
          <div className="col-6">
            <button
              className="border btn btn-outline-dark rounded "
              onClick={() => tables.setPageIndex(tables.getPageCount() - 1)}
              disabled={!tables.getCanNextPage()}
            >
              {" >> "}
            </button>
          </div>
        </div>
        <div className="col d-flex">
          <div className="col-3">
            <span className="d-flex justify-content-center g-1">
              <div>Page</div>
              <strong>
                {tables.getState().pagination.pageIndex + 1} of{" "}
                {tables.getPageCount()}
              </strong>
            </span>
          </div>

          <div className="col-3">
            <span className="d-flex ">
              <div className="col fs-6"> | Go to page:</div>
              <div className="col">
                <input
                  type="number"
                  defaultValue={tables.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    tables.setPageIndex(page);
                  }}
                  className="border  rounded w-16"
                />
              </div>
            </span>
          </div>
          <div className="col-3">
            <select
              value={tables.getState().pagination.pageSize}
              onChange={(e) => {
                tables.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div>{tables.getPrePaginationRowModel().rows.length} Rows</div>
        </div>
      </div>
      <div className="d-flex mt-2 g-3">
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => fetchDataAsync()}
          >
            Refresh Data
          </button>
        </div>
      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
      {instance.instances.length !== 0 && (
        <div className="card position-absolute bottom-0">
          <div className="card-body">

            <table className="table table-striped table-dark table-bordered table-hover">
              <thead className="text-dark">
                <th>id</th>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Action</th>
              </thead>
              <tbody>
                {instance.instances.length > 0 &&
                  instance.instances.map((listItem: any, index: number) => {
                    return (
                      <tr key={index + 1}>
                        <td>{listItem.id}</td>
                        <td>{listItem.itemProduct}</td>
                        <td>{listItem.quantityAmount && (listItem.quantityAmount).toLocaleString("en")}</td>
                        <td>{listItem.discountTotalAmount && (listItem.discountTotalAmount).toLocaleString("en")}</td>
                        <td>{(listItem.itemDiscount) || 0}%</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => removeItem(listItem.id)}
                          >
                            remove item
                          </button>
                        </td>
                      </tr>
                    );
                  })}{" "}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td className="text-center">
                    <strong>{totalPrice}</strong>
                  </td>
                </tr>

              </tfoot>
            </table>
            <div>
              <button className="btn btn-success m-2" onClick={handleCheckout}>
                Check Out
              </button>
              <button className="btn btn-primary m-2" onClick={handleShow}>
                Credit Purchase
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={handleHide}
        size="lg"
        className="align-items-center d-grid w-100"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textTransform: "uppercase" }}>
            Credit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-grid justify-content-center">
          <>
            <h3 className="fw-bold">Checkout Customer on Credit</h3>
            <div className="mt-2">
              <label className="form-label">Customer name
                <input name="customer" className="form-control" onChange={(e) => handleCreditChange(e)} />
              </label>
            </div>

            <div className="row row-col-2 mt-3">
              <div className="col">
                <button className="btn btn-danger" onClick={handleCheckoutCredit}>
                  Checkout
                </button>
              </div>
              <div className="col">
                <button className="btn btn-dark" onClick={handleHide}>
                  Cancel
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div >
  );
}
