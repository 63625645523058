import React, { Fragment, useState } from "react";
import { Collapse } from "react-bootstrap";
import { MdArrowDropDownCircle } from "react-icons/md";
import "../Layout/sidebar/sidebar.css";
import { Link } from "react-router-dom";

interface CollapseProps {
  menu: string;
  sub_menu: any;
  url: string
}
export default function Collapser({ menu, url, sub_menu }: CollapseProps) {
  const userData: string | any = localStorage.getItem("UserData")
  const rData = JSON.parse(userData)
  const userD = rData?.user_data
  // console.log(userD)
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className=" mt-2">
        {
          userD?.role !== "admin" && (menu === "User manager" || menu === "Inventory" || menu === "Deleted Sales") ?

            <button
              className="row row-cols-2 d-none  border-bottom  p-2 w-100"
              onClick={toggle}
              // typeof={"button"}
              aria-expanded={open}
              aria-controls="collapseExample"
            >
              <Link to={url || "#"}><div className="col text-start">{menu}</div></Link>
              <div className="col d-flex justify-content-end ">
                <MdArrowDropDownCircle className="m-1" />
              </div>
            </button>
            : <button
              className="row row-cols-2  border-bottom  p-2 w-100"
              onClick={toggle}
              // typeof={"button"}
              aria-expanded={open}
              aria-controls="collapseExample"
            >
              <Link to={url || "#"}><div className="col text-start">{menu}</div></Link>
              <div className="col d-flex justify-content-end ">
                <MdArrowDropDownCircle className="m-1" />
              </div>
            </button>
        }
      </div>
      {sub_menu &&
        sub_menu.map((item: any, index: number) => {

          return (
            <Fragment key={index + 1}>
              <Link to={item?.url || "#"}>
                {" "}
                <Collapse in={open} className="sub-menu">
                  <div
                    id="example-collapse-text "
                    className="container  pt-2 ps-3 pe-3 pb-2"
                  >
                    {item.name}
                  </div>
                </Collapse>{" "}
              </Link>
            </Fragment>
          );
        })}
    </div>
  );
}
